import React from 'react';
import Onyx from './lib/onyxConfig'; // Import the Onyx configuration
import './App.css';
import HomeScreen from './screens/HomeScreen';
import { GameProvider } from './context/GameContext';
import ThemeProvider from './context/ThemeProvider.tsx';
import { SessionProvider } from './context/SessionContext';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { FocusProvider } from './context/FocusProvider.js';

if (typeof __DEV__ === 'undefined') {
  global.__DEV__ = process.env.NODE_ENV !== 'production';
}

function App() {
  return (
    <SafeAreaProvider>
      <SessionProvider>
        <FocusProvider>
          <ThemeProvider>
            <GameProvider>
              <HomeScreen />
            </GameProvider>
          </ThemeProvider>
        </FocusProvider>
      </SessionProvider>
    </SafeAreaProvider>
  );
}

export default App;
