import React, { useState, useEffect } from 'react';
import '../App.css';
import { useOnyx } from 'react-native-onyx';
import { Text, View, Pressable, Button } from 'react-native-web';
import { useGameContext } from '../context/GameContext';
import CONST from '../CONST';
import ONYXKEYS from '../lib/ONYXKEYS';
import Beer from '../components/Beer';
import SettingsButton from '../components/SettingsButton.tsx';
import ClueZone from '../components/ClueZone.tsx';
import useTheme from '../hooks/useTheme.ts';
import ResultsPage from '../components/ResultsPage.js';
import Settings from '../components/Settings.js';
import H1 from '../components/text/H1.tsx';
import H2 from '../components/text/H2.tsx';
import Title from '../components/text/Title.tsx';
import Subscribe from '../components/Subscribe.js';
import Body from '../components/text/Body.tsx';
import { isMobile } from 'react-device-detect';
import BigButton from '../components/BigButton.tsx';
import { preloadImages } from '../utils/utils.ts';

function HomeScreen() {
  const [pubData] = useOnyx(ONYXKEYS.GAME_DATA);
  const { gameState, startGame } = useGameContext();
  const theme = useTheme();
  const styles = getStyles(theme);
  const [isSettingsVisable, setSettingsVisable] = useState(false);
  const [isResultsVisable, setResultsVisable] = useState(false);
  const [isSubscribeVisible, setSubscribeVisible] = useState(false);
  const [preloadStatus, setPreloadStatus] = useState(false); // Track preload status
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const status = gameState?.status || '';

  const isInProgress = status === CONST.STATUS.IN_PROGRESS;

  useEffect(() => {
    // Apply the theme's background color to the body element
    document.body.style.backgroundColor = theme.appBG;
  }, [theme]); // Re-run this effect when the theme changes

  console.log({ status });

  useEffect(() => {
    // Preload images when pubData.imageUrls are available and not already preloaded
    if (pubData?.imageUrls) {
      preloadImages(pubData.imageUrls, pubData.resultURL, setPreloadStatus);
      setImagesLoaded(true);
    }
  }, [pubData?.imageUrls]);

  useEffect(() => {
    if (status === CONST.STATUS.WON || status === CONST.STATUS.LOST) {
      setResultsVisable(true);
    } else {
      setResultsVisable(false);
    }
  }, [status]);

  if (!status || !gameState || !pubData || !styles || !preloadStatus || !imagesLoaded) {
    return <Text style={styles.bodyText}>Loading...</Text>;
  }

  const getDebugInfo = () => {
    if (process.env.NODE_ENV === 'development') {
      return (
        <View style={styles.debugInfo}>
          <Text style={styles.bodyText}>Current Guess: {gameState.currentGuessIndex}</Text>
          <Text style={styles.bodyText}>Current Image: {gameState.currentImageIndex}</Text>
          <Text style={styles.bodyText}>Past Guesses: {gameState.pastGuesses.join(', ')}</Text>
          <Text style={styles.bodyText}>Status: {status}</Text>
        </View>
      );
    }
    return null;
  };

  const toggleSettingsVisibility = () => {
    setSettingsVisable((prevState) => !prevState);
  };

  const closeSettings = () => {
    setSettingsVisable(false);
  };

  const openSubscribe = () => {
    setSubscribeVisible(true);
  };

  const closeSubscribe = () => {
    setSubscribeVisible(false);
  };

  const openResults = () => {
    setResultsVisable(true);
  };

  const closeResults = () => {
    setResultsVisable(false);
  };

  const getContent = (status) => {
    if (!Object.values(CONST.STATUS).includes(status)) {
      return <Text style={styles.bodyText}>Houston, we have a problem...</Text>;
    }
    switch (status) {
      case CONST.STATUS.NOT_STARTED:
        return (
          <>
            <H1>THINK YOU KNOW LONDON PUBS?</H1>
            <H2>
              5 Guesses.
              <br />1 Legendary Pub.
              <br />
            </H2>
            <BigButton text="Start" onPress={startGame} variant="filled" />
            <H2>NEW CHALLENGE EVERY THURSDAY</H2>
            <Pressable onPress={openSubscribe}>
              <Body style={styles.subscribeText}>Join the club!</Body>
            </Pressable>
          </>
        );
      case CONST.STATUS.IN_PROGRESS:
        return (
          <>
            <ClueZone imageURL={pubData?.imageUrls?.[gameState?.currentImageIndex || 0]} />
          </>
        );
      case CONST.STATUS.WON:
      case CONST.STATUS.LOST:
      default:
        return <BigButton text="Results" onPress={openResults} variant="filled" />;
    }
  };

  return (
    <View style={[styles.app]}>
      <View style={styles.backgroundBeer}>
        <Beer gameIndex={gameState?.currentGuessIndex ?? 0} status={status} />
      </View>
      <Settings shouldShow={isSettingsVisable} onClose={closeSettings} />
      <ResultsPage shouldShow={isResultsVisable} onClose={closeResults} />
      <Subscribe shouldShow={isSubscribeVisible} onClose={closeSubscribe} />
      <View style={styles.banner}>
        <SettingsButton onPress={toggleSettingsVisibility} />
      </View>
      <View
        style={[
          styles.contentContainer,
          isMobile && status === CONST.STATUS.IN_PROGRESS ? { paddingTop: 100 } : {},
        ]}
      >
        <Title enableAnimation={isInProgress}>
          PUB
          <br />
          GENIUS
        </Title>
        <View style={styles.content}>{getContent(status)}</View>
      </View>
      {/* {getDebugInfo()} */}
    </View>
  );
}

const getStyles = (theme) => ({
  app: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundColor: theme.appBG,
    overflow: 'hidden',
    color: theme.textPrimary,
  },
  contentContainer: {
    // display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: 12,
  },
  debugInfo: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    fontFamily: 'open-sans',
    fontSize: 14,
    color: theme.textPrimary,
    margin: 20,
  },
  header: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  body: {
    //   fontFamily: 'open-sans',
    //   fontSize: 14,
    marginTop: '20%', // Keep 20% distance from the top
    flex: 1, // Use flex to allow other elements to take up remaining space
    display: 'flex',
    justifyContent: 'flex-start', // Ensure content is aligned at the start (top)
    alignItems: 'center', // Center content horizontally
    // textAlign: 'center', // Center text horizontally
  },
  h1: {
    //   fontFamily: 'copperplate',
    //   fontSize: 18,
    //   fontWeight: 'bold',
    marginTop: '20%', // Keep 20% distance from the top
    flex: 1, // Use flex to allow other elements to take up remaining space
    display: 'flex',
    justifyContent: 'flex-start', // Ensure content is aligned at the start (top)
    alignItems: 'center', // Center content horizontally
  },
  h2: {
    //   fontFamily: 'copperplate',
    //   fontSize: 14,
    //   fontWeight: 'bold',
    marginTop: '20%', // Keep 20% distance from the top
    flex: 1, // Use flex to allow other elements to take up remaining space
    display: 'flex',
    justifyContent: 'flex-start', // Ensure content is aligned at the start (top)
    alignItems: 'center', // Center content horizontally
  },
  backgroundBeer: {
    position: 'absolute',
    top: 0,
    left: 0,
    minHeight: '100%',
    width: '100%',
    // height: '100%',
    zIndex: -1, // Make sure Beer is in the background
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: 20,
    flexGrow: 1,
    gap: 12,
  },
  banner: {
    position: 'absolute',
    top: 0,
    right: 0,
    alignItems: 'center',
    zIndex: 1000,
    padding: '0 20px',
  },
});

export default HomeScreen;
