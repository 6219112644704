import React from 'react';
import { View, Text, Button, Pressable, TouchableOpacity } from 'react-native-web';
import Onyx, { useOnyx } from 'react-native-onyx';
import CONST from '../CONST';
import ONYXKEYS from '../lib/ONYXKEYS';
import { useGameContext } from '../context/GameContext';
import useTheme from '../hooks/useTheme.ts';
import ImageWithContent from './ImageWithContent.tsx';
import H1 from './text/H1.tsx';
import H2 from './text/H2.tsx';
import Body from './text/Body.tsx';
import Title from './text/Title.tsx';
import Divider from './Divider.tsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CloseButton from './CloseButton.tsx';
import SubmitButton from './SubmitInputButton.tsx';
import ButtonText from './text/ButtonText.tsx';
import { BsBorderWidth } from 'react-icons/bs';
import BigButton from './BigButton.tsx';

function ResultsPage({ pubName, pubLocation, pubImageURL, shouldShow, onClose }) {
  const [pubData] = useOnyx(ONYXKEYS.GAME_DATA);
  const { gameState } = useGameContext();
  const status = gameState?.status || '';
  const theme = useTheme();
  const styles = getStyles(theme);

  if (!gameState) {
    return <Text>Loading...</Text>;
  }

  if (!pubData) {
    return <Text>Loading...</Text>;
  }
  const getTitle = (status) => {
    switch (status) {
      case CONST.STATUS.WON:
        return <Title>Cheers!</Title>;
      case CONST.STATUS.LOST:
        return <Title>You're Barred!</Title>;
      default:
        return <Title>Loading...</Title>;
    }
  };

  const getSubtitle = (status) => {
    switch (status) {
      case CONST.STATUS.WON:
        return (
          <>
            <H1 style={styles.H1}>You got it!</H1>
            {gameState.currentGuessIndex == 1 ? (
              <H2 style={styles.label}>Only took {gameState.currentGuessIndex} guess!</H2>
            ) : (
              <H2 style={styles.label}>It only took you {gameState.currentGuessIndex} guesses!</H2>
            )}
          </>
        );
      case CONST.STATUS.LOST:
        return (
          <>
            <H1 style={styles.H1}>Better luck next time!</H1>
            <H2 style={styles.label}>Maybe it's time to head for a pint...</H2>
          </>
        );
      default:
        return (
          <>
            <H1 style={styles.H1}>Better luck next time!</H1>
            <H2 style={styles.label}>Maybe it's time to head for a pint...</H2>
          </>
        );
    }
  };

  const shareContent = {
    title: 'Pub Genius Results',
    text: `I scored ${6 - gameState.currentGuessIndex} on Pub Genius! Think you can beat me?`,
    url: 'https://pubgenius.co.uk',
  };

  const handleShare = () => {
    const isMobile = window.matchMedia('(hover: none) and (pointer: coarse)').matches;

    if (isMobile && navigator.share) {
      navigator
        .share(shareContent)
        .then(() => console.log('Shared successfully!'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      const textToCopy = `${shareContent.text} Play here: ${shareContent.url}`;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toast.success('Copied to Clipboard. Share your result with your friends!', {
            position: 'top-right',
            autoClose: 4000,
            style: styles.toastSuccess,
          });
        })
        .catch(() => {
          toast.error('Failed to copy.', {
            position: 'top-right',
            autoClose: 4000,
            style: styles.toastError,
          });
        });
    }
  };

  if (!shouldShow) {
    return null;
  }

  return (
    <>
      <View style={styles.root}>
        <View style={styles.closeButton}>
          <CloseButton onPress={onClose} />
        </View>
        <View style={styles.contentContainer}>
          <View>{getTitle(status)}</View>
          <ImageWithContent imageURL={pubData.resultURL}>
            <H1>{pubData.name || 'Pub on the Park'}</H1>
          </ImageWithContent>
          <Divider />
          <View>
            <H2>Location: {pubData.location}</H2>
            <H2>Founded: {pubData.founded || 'Unknown'}</H2>
          </View>
          <Text>{pubData.fact || 'Interesting pub fact will appear here.'}</Text>
          <Divider />
          {getSubtitle(status)}
          <BigButton text="Share" onPress={handleShare} />
        </View>
      </View>
      <ToastContainer />
    </>
  );
}

const getStyles = (theme) => ({
  root: {
    backgroundColor: theme.appBG,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflowY: 'auto',
    padding: 20,
    boxSizing: 'border-box',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
    textAlign: 'center',
    width: '100%',
    maxWidth: 500,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 396,
    textAlign: 'center',
    gap: 20,
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
  actualContentContainer: {
    // flex: 1,
  },
  button: {
    minWidth: 124,
    padding: 0,
    height: 48,
    backgroundColor: theme.appBG,
    borderColor: theme.textPrimary,
    borderWidth: 2,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.textPrimary,
    borderRadius: 32,
  },
  toastSuccess: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontWeight: 300,
    fontStyle: 'regular',
    color: theme.textPrimary,
    borderRadius: '8px', // Rounded corners
    padding: '10px', // Padding inside the toast
  },
  imageContainer: {
    flex: 1,
  },
  pubInfo: {
    wordWrap: 'break-word', // Handle long text
    width: '100%',
    maxWidth: 400,
  },
  pubLocationFounded: {
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%', // Use full width of the container
    maxWidth: 500, // Prevent overflow in small screens
    flexWrap: 'wrap', // Allow rows to wrap if content overflows
    gap: 10, // Add spacing between items
  },
  shareButton: {
    marginTop: 20,
  },
});

export default ResultsPage;
