function hexToRGBA(hex: string, alpha: number | string) {
  // Check if the hex color is valid
  const isValidHex = /^#([0-9A-Fa-f]{3}){1,2}$/.test(hex);
  if (!isValidHex) {
    throw new Error('Invalid hex color format. Expected #RRGGBB or #RGB.');
  }

  // If the hex color is 3 characters long (e.g., #FFF), expand it to 6 characters
  if (hex.length === 4) {
    hex =
      '#' +
      hex
        .slice(1)
        .split('')
        .map((c) => c + c)
        .join('');
  }

  // Convert the hex color to RGB
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  // Return the RGBA color
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

const preloadImages = (
  imageURLs: string[],
  resultURL: string,
  setPreloadStatus: (arg0: boolean) => {}
) => {
  const images = [...imageURLs, resultURL];
  const preloadPromises = images.map((url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(`Image loaded: ${url}`);
      img.onerror = () => reject(`Failed to load image: ${url}`);
    });
  });

  Promise.all(preloadPromises)
    .then((results) => {
      console.log(results);
      setPreloadStatus(true);
    })
    .catch((error) => {
      console.error(error);
      setPreloadStatus(false);
    });
};

export { hexToRGBA, preloadImages };
