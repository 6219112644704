import React, { useState } from 'react';
import { View, Text, TextInput, Button, TouchableWithoutFeedback, Alert } from 'react-native-web';
import useTheme from '../hooks/useTheme.ts';
import H1 from './text/H1.tsx';
import Body from './text/Body.tsx';

const Subscribe = ({ shouldShow = false, onClose }) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubscribe = async () => {
    const API_KEY = process.env.REACT_APP_MAILERLITE_API_KEY;
    const MAILERLITE_URL = 'https://connect.mailerlite.com/api/subscribers';
    const GROUP_ID = process.env.REACT_APP_MAILERLITE_GROUP_ID;

    const payload = {
      email: email, // Subscriber's email
      fields: {
        // Optional additional fields like name
        name: name,
      },
      groups: [GROUP_ID],
    };

    setIsLoading(true);

    try {
      const response = await fetch(MAILERLITE_URL, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${API_KEY}`, // Correct Authorization format
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        console.log('Subscribed successfully!');
        Alert.alert(
          'Subscription Successful',
          'You are now subscribed to exclusive clues and updates!'
        );
        onClose(); // Close the modal after successful subscription
      } else {
        const errorData = await response.json();
        console.error('Error subscribing:', errorData);
        Alert.alert('Error', 'Failed to subscribe. Please try again.');
      }
    } catch (error) {
      console.error('Error subscribing:', error);
      Alert.alert('Error', 'An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!shouldShow) {
    return null;
  }

  return (
    <TouchableWithoutFeedback onPress={onClose}>
      <View style={styles.subscribemodal}>
        <TouchableWithoutFeedback onPress={() => {}}>
          <View style={styles.content}>
            <View style={styles.Row}>
              <Button title="Close" onPress={onClose} />
            </View>
            <H1 style={styles.h1}>Unlock Exclusive Clues and Monthly Fun!</H1>
            <Body style={styles.body}>
              Want to outsmart your friends? Get hints straight to your inbox and never miss a
              puzzle!
            </Body>
            <TextInput
              style={styles.input}
              placeholder="Name"
              value={name}
              onChangeText={setName}
            />
            <TextInput
              style={styles.input}
              placeholder="Email"
              value={email}
              onChangeText={setEmail}
            />
            <Button title="Subscribe" onPress={handleSubscribe} />
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  );
};

const getStyles = (theme) => ({
  subscribemodal: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2000,
  },
  content: {
    backgroundColor: theme.beerLight,
    padding: 20,
    borderRadius: 10,
    width: '80%',
    maxWidth: 400,
    alignItems: 'center',
  },
  h1: {
    marginBottom: 10,
    color: theme.textPrimary,
  },
  body: {
    marginBottom: 20,
    textAlign: 'center',
    color: theme.textSecondary,
  },
  input: {
    backgroundColor: 'transparent',
    paddingVertical: 10, // Add vertical padding to give space for the cursor
    paddingHorizontal: 20, // Horizontal padding for the caret
    fontSize: 14,
    fontFamily: 'copperplate',
    textTransform: 'uppercase',
    textAlign: 'center', // Keep the text left
    outlineWidth: 0,
    color: theme.textPrimary,
    borderWidth: 1, // Thickness of the border
    borderColor: '#ccc', // Color of the border (light gray in this case)
    borderRadius: 5,
  },
  Row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
});

export default Subscribe;
