import supabase from '../lib/supabaseClient';
import { Puzzle } from '../types/onyxTypes/Puzzle';

const fetchWeeklyPuzzle = async () => {
  const today = new Date().toISOString().split('T')[0]; // Current date in YYYY-MM-DD

  try {
    const { data, error } = await supabase
      .from('pubs')
      .select('*')
      .eq('is_published', true)
      .lte('release_date', today) // Fetch puzzles released up to today's date
      .order('release_date', { ascending: false })
      .limit(1); // Get the latest active puzzle

    if (error) throw error;
    const puzzle = data?.[0] || null;

    console.log('Fetched puzzle:', puzzle);

    if (puzzle) {
      const structuredPuzzle: Puzzle = {
        id: puzzle.id,
        name: puzzle.name.toUpperCase(),
        imageUrls: [], // this is not currently used
        description: puzzle.description,
        releaseDate: puzzle.release_date,
        location: puzzle.details.address,
        founded: puzzle.details.founded,
        fact: puzzle.details.fact,
      };
      return structuredPuzzle;
    }

    return null;
  } catch (error) {
    console.error('Error fetching weekly puzzle:', error);
    return null;
  }
};

const fetchGameStats = async (pubID: number) => {
  try {
    const { data, error } = await supabase.from('game_stats').select('*').eq('pub_id', pubID);

    if (error) throw error;

    const gameStats = data?.[0] || null;

    console.log('Fetched stats:', gameStats);

    if (gameStats) {
      return gameStats;
    }

    return null;
  } catch (error) {
    console.error('Error fetching weekly puzzle:', error);
    return null;
  }
};

async function getAllImageUrls(pubId: string | number) {
  const bucketName = 'pub_images';
  const folderName = `pub${pubId}`;

  console.log('grabbing images ', { bucketName, folderName });

  const { data, error } = await supabase.storage.from(bucketName).list(folderName, {
    limit: 10,
    offset: 0,
  });

  console.log('images respone', { data, error });

  if (error) {
    console.error('Error listing files:', error);
    return [];
  }

  // For some reason the current query returns an .emptyFolderPlaceholder file
  const imageUrls = data.map((file) => {
    if (file.name === '.emptyFolderPlaceholder') {
      return null;
    }

    return `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${bucketName}/${folderName}/${file.name}`;
  });

  const resultURLs = imageUrls.filter((url) => url !== null && url.includes('result'));
  const cluesURLs = imageUrls.filter((url) => url !== null && !url.includes('result'));

  return { resultURL: resultURLs[0], cluesURLs };
}

async function postGameSession({ pubID, userID, anonymousID, number_guesses, results, isWinner }) {
  const { data, error } = await supabase.from('game_sessions').upsert(
    {
      pub_id: pubID, // int
      user_id: userID, // uuid
      anonymous_id: anonymousID, // uuid
      number_guesses: number_guesses, // int
      results: results, // JSON
      test: true, // TEST data
      // TODO: uncomment the following when we "go live"
      // test: (process.env.NODE_ENV !== 'production') ? true : false,
      winner: isWinner,
    },
    { onConflict: ['pub_id', 'anonymous_id'] }
  );

  if (error) {
    console.error('Error inserting game session:', error);
    return null;
  }

  console.log('Game session inserted or updated:', data);
  return data;
}

async function postAnonymousSession(session) {
  const { anonymousID } = session;
  if (!anonymousID) {
    return null;
  }
  const { data, error } = await supabase.from('users').upsert(
    {
      anonymous_id: anonymousID,
      created_at: session.sessionStart || new Date().toISOString(),
      updated_at: session.lastActive || new Date().toISOString(),
      test: true,
    },
    { onConflict: 'anonymous_id' }
  );

  if (error) {
    console.error('Error syncing session to Supabase:', error);
    return null;
  } else {
    console.log('Session synced to Supabase:', data);
    return data;
  }
}

async function signInAnonymously() {
  const { data, error } = await supabase.auth.signInAnonymously();

  if (error) {
    console.error('Anonymous sign-in failed:', error);
    return null;
  }

  console.log('Anonymous user signed in:', data);
  return data;
}

async function getSessionFromDB() {
  const { data, error } = await supabase.auth.getSession();

  if (error) {
    console.error('Get Session from DB failed:', error);
    return null;
  }

  console.log('Session:', data);
  return data;
}

export {
  fetchWeeklyPuzzle,
  fetchGameStats,
  getAllImageUrls,
  signInAnonymously,
  postAnonymousSession,
  getSessionFromDB,
  postGameSession,
};
