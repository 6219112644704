import CONST from '../CONST';

export type Theme = {
  appBG: string;
  beerLight: string;
  beerDark: string;
  beerFoam: string;
  textPrimary: string;
  buttonBG: string;
  buttonText: string;
  icon: string;
  inputPlaceholder: string;
  inputBackground: string;
  inputOutline: string;
  inverseBeer: string;
  guiness: string;
  lager: string;
  white: string;
};

const colors = {
  maroon: '#30180F',
  white: '#FAFAFA',
  beerOrangeDark: '#EB931A',
  beerOrangeLight: '#EBA13F',
  offWhite: '#EDE6E0',
  guinnessDark: '#150F0F',
  guinnessLight: '#161010',
  cream: '#FCECD2',
  lightGray: '#DBD7D1',
  darkGray: '#1E1E1E',
  black: '#000000',
};

export const themes: { [key: string]: Theme } = {
  [CONST.THEME.LAGER]: {
    appBG: colors.lightGray,
    beerLight: colors.beerOrangeLight,
    beerDark: colors.beerOrangeDark,
    beerFoam: colors.offWhite,
    textPrimary: colors.maroon,
    buttonBG: colors.maroon,
    buttonText: colors.offWhite,
    icon: colors.maroon,
    inputPlaceholder: colors.lightGray,
    inputBackground: colors.offWhite,
    inputOutline: colors.maroon,
    inverseBeer: colors.guinnessDark,
    guiness: colors.guinnessDark,
    lager: colors.beerOrangeDark,
    white: colors.white,
  },
  [CONST.THEME.GUINNESS]: {
    appBG: colors.darkGray,
    beerLight: colors.guinnessLight,
    beerDark: colors.guinnessDark,
    beerFoam: colors.cream,
    textPrimary: colors.offWhite,
    buttonBG: colors.offWhite,
    buttonText: colors.maroon,
    icon: colors.offWhite,
    inputPlaceholder: colors.darkGray,
    inputBackground: colors.offWhite,
    inputOutline: colors.maroon,
    inverseBeer: colors.beerOrangeDark,
    guiness: colors.guinnessDark,
    lager: colors.beerOrangeDark,
    white: colors.white,
  },
};
