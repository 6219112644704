import React, { useEffect, useRef, useState } from 'react';
import { useGameContext } from '../context/GameContext';
import useTheme from '../hooks/useTheme.ts';
import type { Theme } from '../styles/themes.ts';
import ImageWithContent from './ImageWithContent.tsx';
import { View, Animated, Pressable, TextInput as RNTextInput } from 'react-native-web';
import { isMobile } from 'react-device-detect';
import { useFocusContext } from '../context/FocusProvider.js';
import { IoArrowUp } from 'react-icons/io5';
import { hexToRGBA } from '../utils/utils.ts';
import CONST from '../CONST.js';

interface ClueZoneProps {
  imageURL: string;
}

function ClueZone({ imageURL = '' }: ClueZoneProps) {
  const { updateGameState } = useGameContext();
  const { setInputFocused } = useFocusContext();
  const [input, setInput] = useState('');
  const inputRef = useRef<RNTextInput | null>(null);
  const theme = useTheme();
  const styles = getStyles(theme);

  const IMAGE_HEIGHT_SMALL = isMobile ? 225 : 300;
  const IMAGE_WIDTH_SMALL = isMobile ? 300 : 400;

  const IMAGE_HEIGHT_LARGE = isMobile ? 270 : 300;
  const IMAGE_WIDTH_LARGE = isMobile ? 360 : 400;

  const [imageHeight] = useState(new Animated.Value(IMAGE_HEIGHT_LARGE));
  const [imageWidth] = useState(new Animated.Value(IMAGE_WIDTH_LARGE));

  const cleanInput = (input: string) => input.trim().toUpperCase().replace(/\s+/g, ' ');

  const handleKeyPress = (e) => {
    if (isMobile) {
      return;
    }

    // Focus the input field whenever any key is pressed (web/desktop)
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const keyboardWillShow = (event) => {
    setInputFocused(true);
    if (!isMobile) return;
    Animated.timing(imageHeight, {
      duration: event.duration || 300,
      toValue: IMAGE_HEIGHT_SMALL,
    }).start();
    Animated.timing(imageWidth, {
      duration: event.duration || 300,
      toValue: IMAGE_WIDTH_SMALL,
    }).start();
  };

  const keyboardWillHide = (event) => {
    setInputFocused(false);
    if (!isMobile) return;
    Animated.timing(imageHeight, {
      duration: event.duration || 300,
      toValue: IMAGE_HEIGHT_LARGE,
    }).start();

    Animated.timing(imageWidth, {
      duration: event.duration || 300,
      toValue: IMAGE_WIDTH_LARGE,
    }).start();
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleGuess = () => {
    const answer = cleanInput(input);
    if (!answer) {
      return;
    }
    updateGameState(answer);
    setInput('');
    setInputFocused(false);
    if (!isMobile) {
      setTimeout(() => {
        inputRef.current?.focus();
        setInputFocused(true);
      }, 50);
    }
  };

  return (
    <ImageWithContent imageURL={imageURL} height={imageHeight} width={imageWidth}>
      <View style={styles.inputContainer}>
        <RNTextInput
          ref={inputRef}
          autoFocus
          style={[styles.input]}
          placeholder={CONST.COPY.INPUT.PLACEHOLDER}
          placeholderTextColor={hexToRGBA(theme.inputOutline, 0.3)}
          onChangeText={setInput}
          value={input}
          returnKeyType="done"
          onSubmitEditing={handleGuess}
          onFocus={() => keyboardWillShow({ duration: 300 })}
          onBlur={() => keyboardWillHide({ duration: 300 })}
        />
        <View style={{ padding: 4, flexShrink: 1, alignItems: 'flex-end' }}>
          <Pressable
            style={styles.submitButton}
            onPress={() => {
              handleGuess();
            }}
          >
            <IoArrowUp color={theme.inputBackground} />
          </Pressable>
        </View>
      </View>
    </ImageWithContent>
  );
}

const getStyles = (theme: Theme) => ({
  submitButton: {
    backgroundColor: theme.inputOutline,
    borderRadius: 24,
    height: 32,
    width: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputContainer: {
    width: 360,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    backgroundColor: theme.inputBackground,
    borderRadius: 32,
    display: 'flex',
    borderColor: theme.inputOutline,
    borderWidth: 2,
  },
  input: {
    backgroundColor: 'transparent',
    paddingVertical: 10, // Add vertical padding to give space for the cursor
    paddingHorizontal: 16, // Horizontal padding for the caret
    fontSize: 16,
    fontFamily: 'copperplate',
    textTransform: 'uppercase',
    textAlign: 'left',
    outlineWidth: 0,
    // color: theme.textPrimary,
    flex: 1,
  },
});

export default ClueZone;
