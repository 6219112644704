import React from 'react';
import { Text, TouchableOpacity, TextStyle, ViewStyle } from 'react-native';
import { IoBeer, IoBeerSharp } from 'react-icons/io5';
import useTheme from '../hooks/useTheme.ts';
import type { Theme } from '../styles/themes.ts';

// Define the prop type for SettingsButton
interface SettingsButtonProps {
  text: string; // The text prop that will be passed to the button
  onPress: () => void; // Callback to handle button press
}

function SettingsButton({ text, onPress }: SettingsButtonProps) {
  const theme = useTheme();
  const styles = getStyles(theme);
  return (
    <TouchableOpacity style={styles.settingsButton} onPress={onPress}>
      <IoBeer color={theme.textPrimary} style={styles.icon} />
    </TouchableOpacity>
  );
}

const getStyles = (theme: Theme) => ({
  icon: {
    width: 32,
    height: 32,
  },
  settingsButton: {
    margin: 10,
    borderRadius: 20,
    marginRight: 10,
  },
});

export default SettingsButton;
