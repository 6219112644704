import React, { createContext, useContext, useState, useEffect } from 'react';
import Onyx from 'react-native-onyx';
import ONYXKEYS from '../lib/ONYXKEYS';
import { useOnyx } from 'react-native-onyx';
import { postAnonymousSession, signInAnonymously } from '../utils/data.ts';

const SessionContext = createContext(null);

export const SessionProvider = ({ children }) => {
  const [session, setSessionState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sessionData, sessionDataResult] = useOnyx(ONYXKEYS.SESSION);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    if (sessionDataResult.status === 'loading') {
      setIsLoading(true);
    } else if (sessionDataResult.status === 'loaded') {
      console.log('SESSION LOADED: ', { isLoading, sessionData, sessionDataResult, session });

      handleFetchData();
      setIsLoading(false);
    }
  }, [sessionDataResult.status, isLoading]);

  const handleFetchData = async () => {
    if (!sessionData) {
      const { user } = await signInAnonymously();
      console.log('>>> ANON: ', { user });

      const newSession = {
        anonymousID: user.id,
        sessionStart: user.created_at || new Date().toISOString(),
        lastActive: user.created_at || new Date().toISOString(),
      };
      Onyx.set(ONYXKEYS.SESSION, newSession);
      setSessionState(newSession);
      postAnonymousSession(newSession);
    } else {
      const updatedSession = {
        ...sessionData,
        lastActive: new Date().toISOString(),
      };

      Onyx.merge(ONYXKEYS.SESSION, updatedSession);
      setSessionState(updatedSession);
      postAnonymousSession(updatedSession);
    }
  };

  const setSession = (value) => {
    setSessionState(value);
    Onyx.merge(ONYXKEYS.SESSION, value);
    postAnonymousSession({ value });
  };

  return (
    <SessionContext.Provider value={{ session, isLoading, setSession }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};
