import React from 'react';
import { Text, TouchableOpacity, TextStyle, ViewStyle } from 'react-native';
import useTheme from '../hooks/useTheme.ts';
import type { Theme } from '../styles/themes.ts';

interface BigButtonProps {
  onPress: () => void; // Callback to handle button press
  variant?: 'filled' | 'outline';
  text?: string;
}

function BigButton({ onPress, variant = 'filled', text = 'Start' }: BigButtonProps) {
  const theme = useTheme();
  const isOutline = variant === 'outline';
  const styles = getStyles(theme, isOutline);

  return (
    <TouchableOpacity style={isOutline ? styles.buttonOutline : styles.button} onPress={onPress}>
      <Text style={styles.buttonText}>{text}</Text>
    </TouchableOpacity>
  );
}

const getStyles = (theme: Theme, isOutline: boolean) => ({
  buttonOutline: {
    minWidth: 124,
    padding: 0,
    height: 48,
    backgroundColor: theme.appBG,
    borderColor: theme.textPrimary,
    borderWidth: 2,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.textPrimary,
    borderRadius: 32,
  },
  button: {
    minWidth: 124,
    padding: 0,
    height: 48,
    backgroundColor: theme.textPrimary,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.beerFoam,
    borderRadius: 32,
  },
  buttonText: {
    fontFamily: 'Open Sans',
    fontSize: 15,
    fontWeight: 600,
    fontStyle: 'bold',
    color: isOutline ? theme.textPrimary : theme.buttonText,
  },
});

export default BigButton;
