const CONST = {
  STATUS: {
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    WON: 'WON',
    LOST: 'LOST',
  },
  THEME: {
    LAGER: 'light',
    GUINNESS: 'dark',
  },
  COPY: {
    LANDING_PAGE: {
      HEADING: 'THINK YOU KNOW LONDON PUBS?',
    },
    INPUT: {
      PLACEHOLDER: 'Name that pub!',
    },
  },
};

export default CONST;
