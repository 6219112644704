import React, { useEffect, useState } from 'react';
import useTheme from '../../hooks/useTheme.ts';
import type { Theme } from '../../styles/themes.ts';
import { isMobile } from 'react-device-detect';
import { Animated } from 'react-native-web';
import { useFocusContext } from '../../context/FocusProvider.js';

interface TitleProps {
  children?: React.ReactNode;
  enableAnimation?: boolean;
}

function Title({ children, enableAnimation }: TitleProps) {
  const theme = useTheme();
  const styles = getStyles(theme);

  const FONT_SIZE_SMALL = isMobile ? 30 : 30;
  const FONT_SIZE_LARGE = isMobile ? 50 : 50;

  const [fontSize] = useState(new Animated.Value(FONT_SIZE_LARGE));

  const { isInputFocused } = useFocusContext();

  const shouldAnimate = enableAnimation && isMobile;

  // Use the input focus state to adjust the title size
  useEffect(() => {
    if (!shouldAnimate) return;
    Animated.timing(fontSize, {
      duration: 300,
      toValue: isInputFocused ? FONT_SIZE_SMALL : FONT_SIZE_LARGE,
      useNativeDriver: false, // `fontSize` doesn't support native driver
    }).start();
  }, [isInputFocused]);

  return (
    <Animated.Text
      style={[
        styles.title,
        shouldAnimate ? { fontSize: fontSize } : {},
        shouldAnimate && isInputFocused ? { paddingTop: 80 } : {},
      ]}
    >
      {children}
    </Animated.Text>
  );
}

const getStyles = (theme: Theme) => ({
  title: {
    fontFamily: 'Libre Baskerville',
    fontSize: 50,
    fontWeight: 700,
    fontStyle: 'bold',
    color: theme.textPrimary,
    textAlign: 'center',
    lineHeight: '110%',
  },
});

export default Title;
