import React from 'react';
import { Text, View, TouchableOpacity, TextStyle, ViewStyle } from 'react-native';
import { IoClose } from 'react-icons/io5';
import useTheme from '../hooks/useTheme.ts';
import type { Theme } from '../styles/themes.ts';

interface CloseButtonProps {
  onPress: () => void; // Callback to handle button press
}

function CloseButton({ onPress }: CloseButtonProps) {
  const theme = useTheme();
  const styles = getStyles(theme);
  return (
    <TouchableOpacity style={styles.CloseButton} onPress={onPress}>
      <View style={[{ flex: 1 }]}>
        <IoClose color={theme.textPrimary} style={styles.icon} />
      </View>
    </TouchableOpacity>
  );
}

const getStyles = (theme: Theme) => ({
  icon: {
    width: 32,
    height: 32,
  },
  CloseButton: {
    margin: 10,
    borderRadius: 20,
    marginRight: 10,
    flex: 1,
  },
});

export default CloseButton;
