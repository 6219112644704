import React from 'react';
import { Text } from 'react-native';
import useTheme from '../../hooks/useTheme.ts';
import type { Theme } from '../../styles/themes.ts';

interface H2Props {
  children?: React.ReactNode;
}

function H2({ children }: H2Props) {
  const theme = useTheme();
  const styles = getStyles(theme);

  return <Text style={styles.h2}>{children}</Text>;
}

const getStyles = (theme: Theme) => ({
  h2: {
    fontFamily: 'copperplate, Georgia, serif',
    fontSize: 20,
    fontWeight: 700,
    color: theme.textPrimary,
    textAlign: 'center',
  },
});

export default H2;
