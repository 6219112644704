import React, { createContext, useState, useContext } from 'react';

const FocusContext = createContext();

export const FocusProvider = ({ children }) => {
  const [isInputFocused, setInputFocused] = useState(false);

  return (
    <FocusContext.Provider value={{ isInputFocused, setInputFocused }}>
      {children}
    </FocusContext.Provider>
  );
};

export const useFocusContext = () => useContext(FocusContext);
