import React from 'react';
import { View, Image } from 'react-native';
import useTheme from '../hooks/useTheme.ts';
import type { Theme } from '../styles/themes.ts';
import { Animated } from 'react-native-web';

interface ImageWithContentProps {
  imageURL: string;
  children?: React.ReactNode;
  height?: number | string;
  width?: number | string;
}

function ImageWithContent({ imageURL, children, height, width }: ImageWithContentProps) {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <View style={styles.root}>
      <Animated.View style={[styles.imageContainer, { height: height, width: width }]}>
        <Image
          source={{ uri: imageURL }}
          style={styles.image}
          resizeMode="cover"
          onError={(e) => console.log('Image loading error:', e.nativeEvent.error)}
        />
      </Animated.View>
      <View style={styles.contentContainer}>{children}</View>
    </View>
  );
}

const getStyles = (theme: Theme) => ({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: 16,
  },
  imageContainer: {
    width: 400,
    height: 300,
    overflow: 'hidden',
    backgroundColor: theme.appBG,
    borderColor: theme.textPrimary,
    borderWidth: 2,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  contentContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
});

export default ImageWithContent;
