import React, { useState, useEffect } from 'react';
import { View, Switch, Button, TouchableWithoutFeedback } from 'react-native-web';
import Onyx from 'react-native-onyx';
import CONST from '../CONST';
import ONYXKEYS from '../lib/ONYXKEYS';
import useThemePreference from '../hooks/useThemePreference.ts';
import useTheme from '../hooks/useTheme.ts';
import H1 from './text/H1.tsx';
import Body from './text/Body.tsx';
import CloseButton from './CloseButton.tsx';

const Settings = ({ shouldShow = true, onClose }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const themePreference = useThemePreference();
  const toggleInitialState = themePreference === CONST.THEME.GUINNESS;
  const [isEnabled, setIsEnabled] = useState(toggleInitialState);

  useEffect(() => {
    if (themePreference === CONST.THEME.GUINNESS) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  }, [themePreference]);

  const toggleSwitch = () => {
    setIsEnabled((previousState) => {
      const newState = !previousState;

      if (newState) {
        Onyx.set(ONYXKEYS.THEME, CONST.THEME.GUINNESS);
      } else {
        Onyx.set(ONYXKEYS.THEME, CONST.THEME.LAGER);
      }

      return newState;
    });
  };

  if (!shouldShow) {
    return null;
  }

  return (
    <TouchableWithoutFeedback onPress={onClose}>
      <View style={styles.settingsmodal}>
        <TouchableWithoutFeedback onPress={() => {}}>
          <View style={styles.content}>
            <View>
              <View style={styles.row}>
                <H1>Settings</H1>
                <CloseButton onPress={onClose} />
              </View>
              <View style={styles.row}>
                <Body>Dark Mode</Body>
                <Switch
                  activeThumbColor={'#FAFAFA'}
                  activeTrackColor={theme.lager}
                  trackColor={theme.guiness}
                  style={styles.Switch}
                  onValueChange={toggleSwitch}
                  value={isEnabled}
                />
              </View>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  );
};

const getStyles = (theme) => ({
  settingsmodal: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2000,
  },
  content: {
    backgroundColor: theme.beerLight,
    padding: 20,
    borderRadius: 10,
    width: '80%',
    maxWidth: 400,
    alignItems: 'center',
  },
  closeButton: {
    marginLeft: 10,
  },
  Switch: {
    marginLeft: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
});

export default Settings;
